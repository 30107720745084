import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero1 from "../components/Hero/Hero1"
import Recognition from "../components/Recognition"
import HyperAutomation from "../components/HyperAutomation"
import Container from "../components/ui/Container"
import Card1 from "../components/Card/Card1"
import { StaticImage } from "gatsby-plugin-image"
import Cta1 from "../components/Cta/Cta1"
import { useState } from "react";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
const IndexPage = () => (
  <Layout >
    <SEO title="Home" />
  <Container>
        
    <div className="p-[1rem] md:p-[2rem]  grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-2  gap-14 lg:mt-[3rem] place-items-center ">
    <div className="items-end">
            <h1 className="blogft">
             Get sticky customers with <span className="text-red-500">Hyperautomation</span>
            </h1>
            <p className="ppartner lg:mt-[21px]">Make your business grow faster than ever before</p>
            <button className="lg:mt-[21px] bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">
            Book a Demo
            </button>
          </div>
          <div className="items-end ">
            <img
              className="blogimg "
              src="/images/mask.png"
              alt="Sunset in the mountains"
            />
          </div>
         
        
        </div>
        </Container>
<br/>


<div className="p3 grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-4 bg-black lg:p-[7rem] lg:mt-[3rem] p2r">
<div className="partnerw">
            <h1 className="pf1 text-white">
            Get your piece of the
            </h1>
            <h1 className="pf1 text-red-700 lg:mt-[-1rem]">
           $9.2 billion pie
            </h1>
            <p className=" text-white lg:w-[480px]">
            The global hyperautomation market size is to grow from USD 9.2 billion in 2022 to USD 26.0 billion by 2027, at a Compound Annual Growth Rate (CAGR) of 23.1%
            </p>
          
          </div>

          
<div>
<div className="grid grid-cols-2  gap-[2rem]">
    <div className="text-white">
    <img className="lg:w-[4rem]" src="/images/Frame.png"/>
    <p className="mt-[10px]">Become A Leader</p>
    <p >In A Fast Growing Market</p>
    </div>
    <div className="text-white">
    <img className="lg:w-[4rem]" src="/images/Frame.png"/>
    <p className="mt-[10px]">Work with an Agile </p>
    <p> Nimble player</p>
    </div>
    <div className="text-white">
    <img className="lg:w-[4rem]" src="/images/Frame.png"/>
    <p className="mt-[10px]">Differentiate Your Business </p>
    <p > From Your Competition</p>
    </div>
    <div className="text-white">
    <img className="lg:w-[4rem]" src="/images/Frame.png"/>
    <p className="mt-[10px]">Realize value for your solution </p>
    <p > quickly, and easily</p>
    </div>
    </div>
</div>
    </div>


  
<br/><br/>

<Container>
    <h1 className="ph1">Our Partners</h1><br/>
<div className="grid lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-2 gap-4  bgp">
<div className="flex justify-center">
  <div className="block p-6 rounded-lg hover:shadow-lg bg-white max-w-sm">
    <img src="/images/cognito.png"/>

    <p className="mtr1 text-gray-700 text-base mb-4">
      Some quick example text to build on the card title and make up the bulk of the card's
      content.
  </p>
  <span className="icon1"><i className="fa fa-globe" aria-hidden="true"></i>&nbsp; Visit Website</span>
  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6 rounded-lg hover:shadow-lg bg-white max-w-sm">
    <img src="/images/cognito.png"/>

    <p className="mtr1 text-gray-700 text-base mb-4">
      Some quick example text to build on the card title and make up the bulk of the card's
      content.
  </p>
  <span className="icon1"><i className="fa fa-globe" aria-hidden="true"></i> &nbsp;Visit Website</span>
  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6 rounded-lg hover:shadow-lg bg-white max-w-sm">
    <img src="/images/cognito.png"/>

    <p className="mtr1 text-gray-700 text-base mb-4">
      Some quick example text to build on the card title and make up the bulk of the card's
      content.
  </p>
  <span className="icon1"><i className="fa fa-globe" aria-hidden="true"></i>&nbsp; Visit Website</span>
  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6 rounded-lg hover:shadow-lg bg-white max-w-sm">
    <img src="/images/cognito.png"/>

    <p className="mtr1 text-gray-700 text-base mb-4">
      Some quick example text to build on the card title and make up the bulk of the card's
      content.
  </p>
  <span className="icon1"><i className="fa fa-globe" aria-hidden="true"></i>&nbsp; Visit Website</span>
  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6 rounded-lg hover:shadow-lg bg-white max-w-sm">
    <img src="/images/cognito.png"/>

    <p className="mtr1 text-gray-700 text-base mb-4">
      Some quick example text to build on the card title and make up the bulk of the card's
      content.
  </p>
  <span className="icon1"><i className="fa fa-globe" aria-hidden="true"></i>&nbsp; Visit Website</span>
  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6 rounded-lg hover:shadow-lg bg-white max-w-sm">
    <img src="/images/cognito.png"/>

    <p className="mtr1 text-gray-700 text-base mb-4">
      Some quick example text to build on the card title and make up the bulk of the card's
      content.
  </p>
  <span className="icon1"><i className="fa fa-globe" aria-hidden="true"></i>&nbsp; Visit Website</span>
  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6 rounded-lg hover:shadow-lg bg-white max-w-sm">
    <img src="/images/cognito.png"/>

    <p className="mtr1 text-gray-700 text-base mb-4">
      Some quick example text to build on the card title and make up the bulk of the card's
      content.
  </p>
  <span className="icon1"><i className="fa fa-globe" aria-hidden="true"></i>&nbsp; Visit Website</span>
  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6 rounded-lg hover:shadow-lg bg-white max-w-sm">
    <img src="/images/cognito.png"/>

    <p className="mtr1 text-gray-700 text-base mb-4">
      Some quick example text to build on the card title and make up the bulk of the card's
      content.
  </p>
  <span className="icon1"> <i className="fa fa-globe" aria-hidden="true"></i>&nbsp; Visit Website</span>
  </div>
</div>
</div>

</Container>
<br/><br/>



           
            <div className="xl:px-20 px-8  2xl:mx-auto 2xl:container relative z-40 bg-white">
            <br/>
            <h1 className="ph1 mt-[21px] testfont">Testimonial</h1><br/>
                <CarouselProvider naturalSlideWidth={10} naturalSlideHeight={15} isIntrinsicHeight={true} totalSlides={2}>
                  
                
                    <Slider>
                        <Slide index={0} >
                            <div className="flex">
                                <div className="mt-14 md:flex">
                                    <div className="relative lg:w-1/2 sm:w-96 xl:h-96 h-80">
                                        <img src="https://i.ibb.co/4g1D9cv/imgslider1.png" alt="image of profile" className="w-full h-full flex-shrink-0 object-fit object-cover  rounded" />
                                        <div className="w-32 md:flex hidden items-center justify-center absolute top-0 -mr-16 -mt-14 right-0 h-32 bg-indigo-100 rounded-full">
                                            <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonial-svg1.svg" alt="commas" />
                                        </div>
                                    </div>
                                    <div className="md:w-1/3 lg:w-1/3 xl:ml-32 md:ml-20 md:mt-0 mt-4 flex flex-col justify-between">
                                        <div>
                  
                                            <h1 className="testimonialh1">AUGUSTIN GOHIL</h1>
                                            <p className="text-base font-medium leading-4 mt-4 text-gray-600">President, Cognitgo</p>
                                           
                                          
                                            <ul className="flex mt-3">
  <li>
    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star" className="w-4 text-yellow-500 mr-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path>
    </svg>
  </li>
  <li>
    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star" className="w-4 text-yellow-500 mr-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path>
    </svg>
  </li>
  <li>
    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star" className="w-4 text-yellow-500 mr-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path>
    </svg>
  </li>
  <li>
    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star" className="w-4 text-yellow-500 mr-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path>
    </svg>
  </li>
  <li>
    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star" className="w-4 text-yellow-500" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path>
    </svg>
  </li>
</ul>
                                            <p className="text-base font-medium leading-6 mt-3 text-gray-600">We managed to add the capability to send highly personalised responses and improve winnability of deals with RAPFlow and RAPBot. This helped add significant value to our solution and enhanced our competitiveness in the marketplace.</p>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={1}>
                            <div className="flex relative" style={{ transform: 'translateX(0%)' }}>
                                <div className="mt-14 md:flex">
                                    <div className="relative lg:w-1/2 sm:w-96 xl:h-96 h-80">
                                        <img src="https://i.ibb.co/4g1D9cv/imgslider1.png" alt="image of profile" className="w-full h-full flex-shrink-0 object-fit object-cover rounded" />
                                        <div className="w-32 md:flex hidden items-center justify-center absolute top-0 -mr-16 -mt-14 right-0 h-32 bg-indigo-100 rounded-full">
                                            <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonial-svg1.svg" alt="commas" />
                                        </div>
                                    </div>
                                    <div className="md:w-1/3 lg:w-1/3 xl:ml-32 md:ml-20 md:mt-0 mt-4 flex flex-col justify-between">
                                        <div>
                                            <h1 className="text-2xl font-semibold xl:leading-loose text-gray-800">Some of the best work that was done!</h1>
                                            <p className="text-base font-medium leading-6 mt-4 text-gray-600">Our core values are at the heart of all that we do. They are integrated into our daily work lives and help us to remember our customers always comes first, the last thank you should always comes from us.</p>
                                        </div>
                                        <div className="md:mt-0 mt-8">
                                            <p className="text-base font-medium leading-4 text-gray-800">Anna Smith</p>
                                            <p className="text-base leading-4 mt-2 mb-4 text-gray-600">Senior Web Designer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                    </Slider>
                    <div className="flex items-center mt-8">
                        <ButtonBack className="cursor-pointer " role="button" aria-label="previous slide">
                            <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonal-svg2.svg" alt="previous" />
                        </ButtonBack>

                        <ButtonNext role="button" aria-label="next slide" className="cursor-pointer ml-2">
                            <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonial-svg3.svg" alt="next" />
                        </ButtonNext>
                    </div>
                </CarouselProvider>
            </div>
      

  <br/>

<div className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-4 bg-black lg:p-[6rem] p2r">
<div className="partnerw">
            <h1 className="partl text-white psm">
            Venture into  <br/> <span className="parnerred psm">Hyperautomation Today.</span>
            </h1>
         
        
          
          </div>

          
<div className="lg:ml-[16rem] md:ml-[5rem]">
<button className="text-align: right; prtbut bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white  border border-blue-500 hover:border-transparent rounded">
            Book a Demo
            </button>
</div>
    </div>


  

  </Layout>
)

export default IndexPage
